<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="`${$t('Location')}`">
        <template slot="tools">
          <router-link
            :to="
              `/${$route.params.shop_lang}/${$route.params.shop_name}/location/add`
            "
            class="btn btn-primary btn-xs"
          >
            Dodaj lokalizacje
            <i class="fas fa-plus"></i>
          </router-link>
        </template>
        <template slot="content" slot-scope="{ setLoading }">
          <div class="row">
            <div class="col-4">
              <label>
                {{ $t("show") }}
                <select
                  style="display: inline-block; width: 130px"
                  name="visible"
                  aria-controls="visible"
                  class="form-control form-control-sm"
                  v-model="visible"
                >
                  <option :value="1">widoczne</option>
                  <option :value="0">
                    niewidoczne
                  </option>
                </select>
                {{ $t("points") }}
              </label>
            </div>
          </div>
          <DataTable
            :url="ulrList"
            :detailsPath="'localizations'"
            @loading="setLoading"
          />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox";
import DataTable from "@/components/DataTable/DataTable.vue";

export default {
  name: "LocationList",
  components: {
    Ibox,
    DataTable
  },
  computed: {
    ulrList() {
      let queryParams = `?visible=${this.visible}`;
      return (
        `/${this.$route.params.shop_name}/${this.$route.params.shop_lang}/google-maps` +
        queryParams
      );
    }
  },
  data() {
    return {
      visible: 1
    };
  }
};
</script>
